import { HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr'
import { ComputedRef, DeepReadonly, Ref, computed, onUnmounted, ref, watch } from 'vue'

import { useMqttSubscriber } from '@/composables/mqtt-subscriber'
import { useDataStore } from '@/stores/data'
import { ActiveExperience, useExperienceStore } from '@/stores/experience'
import { useLocalStorageStore } from '@/stores/local-storage'

import { checkPlaybackError, debugLog } from '@/utils/error'
import { Exhibit, Experience } from 'types/data'
import { DisplayMessagePayload } from 'types/mqtt-messages'

const DEBUG_SCOPE = 'DisplayConnect'

interface SignalrDisplayInfo {
  displayId: number
  entityId: number
  position: number
  isPlaying: boolean
}

interface UseDisplayConnectParams {
  exhibit: ComputedRef<DeepReadonly<Exhibit> | null>
  onResync?: (time: number) => void | Promise<void>
}

interface UseDisplayConnectReturnValue {
  currentExperience: Ref<Experience | ActiveExperience | null>
}

export function useDisplayConnect (params: UseDisplayConnectParams): UseDisplayConnectReturnValue {
  const experiences = computed(() => params.exhibit.value?.experiences ?? [])
  const currentExperience: UseDisplayConnectReturnValue['currentExperience'] = ref(null)

  const localStorageStore = useLocalStorageStore()
  const experienceStore = useExperienceStore()

  // use a single instance so Safari doesn't restrict playback
  // creating an instance after an interaction may fail
  const currentAudio = new Audio()
  function destroyExperience (): void {
    experienceStore.deactivate()
    currentExperience.value = null
    if (!currentAudio) return
    currentAudio.pause()
    currentAudio.src = ''
  }
  onUnmounted(() => {
    destroyExperience()
  })

  async function handleMessage (payload: { experienceId: number, currentTime: number }): Promise<void> {
    debugLog(DEBUG_SCOPE, `display payload.experienceId: ${payload.experienceId}`)
    if (payload.experienceId === 0) {
      destroyExperience()
      return
    }

    if (payload.experienceId === currentExperience.value?.id) {
      if (Math.abs(currentAudio.currentTime - payload.currentTime) > 1) {
        const newTime = payload.currentTime + 0.1
        if (localStorageStore.isSignLanguage) {
          experienceStore.updatePlayState({ currentTime: newTime })
        } else {
          currentAudio.currentTime = newTime
          try {
            await currentAudio.play()
          } catch (err) {
            if (checkPlaybackError(err)) return
            console.error(err)
          }
        }
        await params.onResync?.(newTime)
      }
    }

    if (payload.experienceId !== currentExperience.value?.id) {
      destroyExperience()
      const experience = experiences.value.find((e) => e.id === payload.experienceId) ?? null
      debugLog(DEBUG_SCOPE, 'new experience')
      debugLog(DEBUG_SCOPE, `experience media: ${experience?.media ? JSON.stringify(experience?.media) : 'none'}`)
      const startTime = payload.currentTime + 0.1
      if (experience?.media) {
        if (localStorageStore.isSignLanguage) {
          experienceStore.activate(experience, true)
          currentExperience.value = experienceStore.activeExperience
          void experienceStore.playPause()
        } else {
          const newSrc = dataStore.getMediaSrc(experience.media)
          try {
            if (currentAudio.src !== newSrc) {
              currentExperience.value = experience
              currentAudio.src = newSrc
              currentAudio.load()
              currentAudio.currentTime = startTime
              await currentAudio.play()
            }
          } catch (err) {
            if (checkPlaybackError(err)) return
            console.error(err)
          }
        }
        await params.onResync?.(startTime)
      }
    }
  }

  const dataStore = useDataStore()
  let unsubscribeFn: ((prevExhibit: DeepReadonly<Exhibit> | null | undefined) => void | Promise<void>) = () => {}
  let subscribeFn: ((exhibit: DeepReadonly<Exhibit>) => void | Promise<void>) = () => {}
  if (dataStore.settings.hasLocationSupport) {
    const { subscribeTopic } = useMqttSubscriber()
    const displaySubscription = ref<(() => void) | null>(null)
    unsubscribeFn = () => {
      displaySubscription.value?.()
    }
    subscribeFn = (exhibit) => {
      // subscribe to entityId as that's what devices send
      const topic = `display/${exhibit?.entityId}`
      debugLog(DEBUG_SCOPE, 'topic: ' + topic)
      displaySubscription.value = subscribeTopic<DisplayMessagePayload>(topic, (playbackPayload) => {
        debugLog(DEBUG_SCOPE, `topic and payload: ${JSON.stringify({ topic, playbackPayload })}`)
        void handleMessage(playbackPayload)
      })
    }
  } else {
    const connectionHost = dataStore.displaySyncUrl
    const connectionEndpoint = 'DisplaySyncHub'

    const connectionUrl = connectionHost + connectionEndpoint
    const connection = new HubConnectionBuilder()
      .withUrl(connectionUrl)
      .withAutomaticReconnect()
      .configureLogging({
        log (logLevel, message) {
          if (logLevel >= LogLevel.Warning) {
            debugLog(DEBUG_SCOPE, message)
          }
        },
      })
      .build()

    connection.on('UpdateDisplayInfo', (displayInfo: SignalrDisplayInfo) => {
      debugLog(DEBUG_SCOPE, `displayInfo: ${JSON.stringify(displayInfo)}`)
      void handleMessage({
        experienceId: displayInfo.isPlaying ? displayInfo.entityId : 0,
        currentTime: displayInfo.position,
      })
    })

    unsubscribeFn = async (prevExhibit) => {
      if (!prevExhibit) return
      if (connection.state !== HubConnectionState.Connected) return
      await connection.invoke('LeaveGroup', prevExhibit.entityId)
      debugLog(DEBUG_SCOPE, `Left group for displayIf ${prevExhibit.entityId}`)
    }

    subscribeFn = async (exhibit) => {
      if (connection.state !== HubConnectionState.Connected) {
        await connection.start()
      }
      await connection.invoke('JoinGroup', exhibit.entityId)
      debugLog(DEBUG_SCOPE, `Joined group for displayIf ${exhibit.entityId}`)
    }

    onUnmounted(() => {
      if (connection.state !== HubConnectionState.Disconnected) {
        void connection.stop()
      }
    })
  }

  watch(params.exhibit, async (curr, prev) => {
    if (curr?.id === prev?.id) return
    debugLog(DEBUG_SCOPE, 'exhibit changed')
    try {
      await unsubscribeFn(prev)
      if (!curr) return
      await subscribeFn(curr)
    } catch (err) {
      debugLog(DEBUG_SCOPE, `Error updating exhibit from ${prev?.id} to ${curr?.id}`)
      console.error(err)
    }
  }, { immediate: true })

  return {
    currentExperience,
  }
}
