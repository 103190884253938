<template>
  <div v-if="exhibit && tour" class="page-container flex-column">
    <ExhibitBanner :exhibit="{ ...exhibit, image: tour.tourImage }" />
    <TourTrackSelector :tour="tour" @select="selectTrack" />
    <ExperiencePlayer
      v-if="!displayDetails.connected"
      :open="showModal"
      :has-next="hasNextExperience"
      @next="onNext"
    />
    <SouvenirItem v-if="exhibit.souvenirId" :exhibit="exhibit" />
  </div>
  <Teleport to="body">
    <Transition name="display-slide">
      <DisplayConnect
        v-if="displayDetails.connected"
        :exhibit="displayDetails.exhibit"
        @disconnect="dismissDisplay"
      />
    </Transition>
  </Teleport>
</template>

<script lang="ts">
import { DeepReadonly, computed, defineComponent, reactive } from 'vue'

import DisplayConnect from '@/components/DisplayConnect.vue'
import ExhibitBanner from '@/components/ExhibitBanner.vue'
import ExperiencePlayer from '@/components/ExperiencePlayer.vue'
import SouvenirItem from '@/components/SouvenirItem.vue'
import TourTrackSelector from '@/components/TourTrackSelector.vue'
import { useExhibitStore } from '@/stores/exhibit'
import { useExperienceStore } from '@/stores/experience'
import { useRouterStore } from '@/stores/router'

import { DISPLAY_ID_OFFSET } from '@/utils/constants'
import { Exhibit, TourPositionMedia } from 'types/data'

export default defineComponent({
  components: {
    DisplayConnect,
    ExhibitBanner,
    TourTrackSelector,
    ExperiencePlayer,
    SouvenirItem,
  },

  setup () {
    const routerStore = useRouterStore()
    const exhibit = computed(() => routerStore.exhibit)
    const tour = computed(() => routerStore.tour)

    const experienceStore = useExperienceStore()
    const showModal = computed(() => !!experienceStore.activeExperience)

    // display
    const displayDetails = reactive({
      connected: false,
      exhibit: null as DeepReadonly<Exhibit> | null,
    })
    const exhibitStore = useExhibitStore()
    function showDisplay (displayId: number | null): void {
      const exhibit = exhibitStore.getExhibitById((displayId ?? 0) + DISPLAY_ID_OFFSET)
      if (!exhibit) {
        console.log('Exhibit not found:', displayId)
        return
      }
      displayDetails.exhibit = exhibit
      displayDetails.connected = true
    }
    function dismissDisplay (): void {
      displayDetails.connected = false
      displayDetails.exhibit = null
    }

    function isActiveTrack (track: TourPositionMedia | null): boolean {
      if (!track || !experienceStore.activeExperience) return false
      return experienceStore.getId(track) === experienceStore.activeExperience.id
    }
    function selectTrack (ev: MouseEvent | null, track: TourPositionMedia | null): void {
      if (!track || isActiveTrack(track)) return
      if (track.isDisplay) {
        experienceStore.deactivate()
        showDisplay(track.displayId)
        return
      }
      experienceStore.activate(track, true)
      void experienceStore.playPause()
      if (ev?.target instanceof HTMLElement) {
        const bounds = ev.target.getBoundingClientRect()
        const distanceFromBottom = window.innerHeight - bounds.bottom
        if (distanceFromBottom < 300) {
          ev.target.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      }
    }

    const nextExperience = computed(() => {
      if (!experienceStore.activeExperience) return null
      const positions = tour.value?.positions ?? []
      const activeId = experienceStore.activeExperience.id
      const idx = positions.findIndex(p => experienceStore.getId(p) === activeId)
      if (idx === -1) return null
      return positions[idx + 1] ?? null
    })
    const hasNextExperience = computed(() => !!nextExperience.value)
    function onNext (): void {
      if (!nextExperience.value) return
      selectTrack(null, nextExperience.value)
    }

    return {
      exhibit,
      tour,
      showModal,
      selectTrack,
      // display
      displayDetails,
      dismissDisplay,

      hasNextExperience,
      onNext,
    }
  },
})
</script>
