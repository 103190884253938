import { defineStore } from 'pinia'
import { computed, readonly, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import { useDataStore } from '@/stores/data'

import { SupportedLanguage } from 'types/data'

import type { ComputedRef, DeepReadonly } from 'vue'

type MappedItems<T extends object> = DeepReadonly<{
  items: Record<string, T>
  getItem: (id: string | number) => T
  setItem: (id: string | number, item: T) => void
  clear: () => void
}>

export interface UserData {
  language: string
  email: string
  tag: string
}
export const USER_DATA_KEY = 'userData'

export interface PlayHistoryItem {
  played: boolean
}
export const PLAY_HISTORY_KEY = 'playHistory'

export interface NotificationHistoryItem {
  lastShown: number
}
export const NOTIFICATION_HISTORY_KEY = 'notificationHistory'

export interface SouvenirHistoryItem {
  sent: boolean
}
export const SOUVENIR_HISTORY_KEY = 'souvenirHistory'

interface LocalStorageReturnValue {
  tag: ComputedRef<string>
  getUserData: () => UserData | null
  setUserData: (userData: UserData) => void
  currentLanguage: ComputedRef<DeepReadonly<SupportedLanguage> | undefined>
  isSignLanguage: ComputedRef<boolean>
  playHistory: MappedItems<PlayHistoryItem>
  notificationHistory: MappedItems<NotificationHistoryItem>
  souvenirHistory: MappedItems<SouvenirHistoryItem>
}

/**
 * Tries to get a storage object from the browser.
 * Security settings may prevent this, so we need to try a few different options.
 *
 * 1. Try to get `localStorage`, private browsing or security settings may prevent this
 * 2. Try to get `sessionStorage`, which is not as persistent but will work
 * 3. If all else fails, use in-memory storage that will not persist refreshes
 */
function getStorage (): Storage {
  try {
    /* istanbul ignore if @preserve */
    if (typeof localStorage.length !== 'number') throw new Error()
    return localStorage
  } catch {
    console.log('Failed to use localStorage, trying sessionStorage')
    try {
      /* istanbul ignore if @preserve */
      if (typeof sessionStorage.length !== 'number') throw new Error()
      return sessionStorage
    } catch {
      console.log('Failed to use sessionStorage, using in-memory storage')
      const storageMap = new Map<string, string>()
      return {
        length: 0,
        /* istanbul ignore next @preserve */
        clear () {
          storageMap.clear()
        },
        getItem (k) {
          return storageMap.get(k) ?? null
        },
        /* istanbul ignore next @preserve */
        key () {
          return null
        },
        /* istanbul ignore next @preserve */
        removeItem (k) {
          storageMap.delete(k)
        },
        setItem (k, v) {
          storageMap.set(k, v)
        },
      }
    }
  }
}

export const useLocalStorageStore = defineStore('localStorage', (): LocalStorageReturnValue => {
  // user data
  const { locale } = useI18n()

  const storage = getStorage()

  /**
   * Safely gets and parses an item from storage.
   * If item is missing or unable to be parsed, returns `null`.
   */
  function getParsedItem<T> (key: string): T | null {
    try {
      const item = storage.getItem(key)
      if (!item) return null
      return JSON.parse(item)
    } catch {
      return null
    }
  }

  /**
   * Creates a binding from a local object map to a place in storage.
   * Will autoload the map on init and keep it in sync.
   */
  function createMappedItems<T extends object> (key: string, defaultItem: () => T): MappedItems<T> {
    const itemsRef = ref<Record<string, T>>({})
    const items = computed(() => itemsRef.value)
    function update (): void {
      storage.setItem(key, JSON.stringify(itemsRef.value))
    }
    const getItem: MappedItems<T>['getItem'] = (id) => {
      const stringId = String(id)
      const item = itemsRef.value[stringId]
      if (item) {
        return item
      } else {
        const newItem = defaultItem()
        itemsRef.value[stringId] = newItem
        update()
        return newItem
      }
    }
    const setItem: MappedItems<T>['setItem'] = (id, item) => {
      itemsRef.value[String(id)] = item
      update()
    }
    const clear: MappedItems<T>['clear'] = () => {
      itemsRef.value = {}
      update()
    }

    const previousItems = getParsedItem<Record<string, T>>(key)
    if (previousItems) {
      itemsRef.value = previousItems
    } else {
      clear()
    }

    return readonly({
      items,
      getItem,
      setItem,
      clear,
    })
  }

  const dataStore = useDataStore()
  const actualTag = ref('')
  const tag: LocalStorageReturnValue['tag'] = computed(() => {
    if (!actualTag.value) return ''
    // always use tag 1 for preview
    return dataStore.settings.previewMode ? '1' : actualTag.value
  })

  const userDataInternal = ref<UserData | null>(null)
  const setUserData: LocalStorageReturnValue['setUserData'] = (userData) => {
    actualTag.value = userData.tag
    locale.value = userData.language
    storage.setItem(USER_DATA_KEY, JSON.stringify(userData))
    userDataInternal.value = userData
  }

  const getUserData: LocalStorageReturnValue['getUserData'] = () => {
    if (!userDataInternal.value) {
      const item = getParsedItem<UserData>(USER_DATA_KEY)
      actualTag.value = item?.tag ?? ''
      locale.value = item?.language ?? 'en'
      userDataInternal.value = item
    }
    return userDataInternal.value
  }

  const currentLanguage = computed(() => dataStore.languages.find(l => l.symbol === locale.value))
  const isSignLanguage = computed(() => !!currentLanguage.value?.isSignLanguage)

  // play history
  const playHistory: LocalStorageReturnValue['playHistory'] = createMappedItems(
    PLAY_HISTORY_KEY,
    (): PlayHistoryItem => ({
      played: false,
    }),
  )

  // notification history
  const notificationHistory: LocalStorageReturnValue['notificationHistory'] = createMappedItems(
    NOTIFICATION_HISTORY_KEY,
    (): NotificationHistoryItem => ({
      lastShown: 0,
    }),
  )

  // souvenir history
  const souvenirHistory: LocalStorageReturnValue['souvenirHistory'] = createMappedItems(
    SOUVENIR_HISTORY_KEY,
    (): SouvenirHistoryItem => ({
      sent: false,
    }),
  )

  return {
    // user data
    tag,
    getUserData,
    setUserData,
    currentLanguage,
    isSignLanguage,
    // play history
    playHistory,
    // notification history
    notificationHistory,
    // souvenir history
    souvenirHistory,
  }
})
