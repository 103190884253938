<template>
  <Teleport to="body">
    <!-- Alert -->
    <div class="modal-overlay" :class="{ 'modal-overlay-visible': alertDetails.show }" />
    <div class="modal-card alert-card flex-center text-center" :class="{ 'modal-card-visible': alertDetails.show }">
      <FontAwesomeIcon icon="fa-warning" :color="cssVariables.error" size="3x" />
      <h1 v-html="alertDetails.title" />
      <p v-html="alertDetails.text" />
      <button class="btn" @click="dismissAlert" v-text="t('ok')" />
    </div>
    <!-- Display -->
    <Transition name="banner-slide">
      <div v-if="displayDetails.show" class="display-banner" @click="openDisplay">
        <p v-text="t(displayDetails.connected ? 'youAreInRangeOfAnotherDisplay' : 'youAreInRange')" />
        <div class="connect-link">
          <p v-text="t('connect')" />
          <FontAwesomeIcon
            class="connect-icon"
            icon="fa-circle-arrow-right"
            color="white"
            size="xl"
          />
        </div>
      </div>
    </Transition>
    <Transition name="display-slide">
      <DisplayConnect
        v-if="displayDetails.connected"
        :exhibit="displayDetails.connectedExhibit"
        @disconnect="dismissDisplay"
      />
    </Transition>
  </Teleport>
</template>

<script lang="ts">
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { DeepReadonly, computed, defineComponent, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import DisplayConnect from '@/components/DisplayConnect.vue'
import { useMqttSubscriber } from '@/composables/mqtt-subscriber'
import { useDataStore } from '@/stores/data'
import { useDemoStore } from '@/stores/demo'
import { useExhibitStore } from '@/stores/exhibit'
import { useExperienceStore } from '@/stores/experience'
import { useLocalStorageStore } from '@/stores/local-storage'
import { useRouterStore } from '@/stores/router'

import { routeNames } from '@/router'
import { TOUR_ID_OFFSET } from '@/utils/constants'
import { cssVariables } from '@/utils/css'
import { checkPlaybackError, debugLog } from '@/utils/error'
import { secondsBetween } from '@/utils/time'
import { Exhibit } from 'types/data'

import type { LocationMessagePayload } from 'types/mqtt-messages'
import type { RouteLocationRaw } from 'vue-router'

const DEBUG_SCOPE = 'LocationWatcher'

export default defineComponent({
  components: {
    FontAwesomeIcon,
    DisplayConnect,
  },

  setup () {
    const { t } = useI18n()
    const localStorageStore = useLocalStorageStore()
    const router = useRouter()
    const route = useRoute()
    const routerStore = useRouterStore()
    const experienceStore = useExperienceStore()

    const onNetworkPage = computed(() => route.name === routeNames.NETWORK)

    async function navigate (location: RouteLocationRaw): Promise<void> {
      if (onNetworkPage.value) return
      experienceStore.deactivate()
      await router.push(location)
    }

    // delayed navigation if playing
    const experiencePlaying = computed(() => {
      return experienceStore.activeExperience?.playState.state === 'playing'
    })
    const delayedNavigation = ref<{ type: 'exhibit' | 'tour', id: number } | null>(null)
    watch(experiencePlaying, (val) => {
      if (!val && delayedNavigation.value) {
        debugLog(DEBUG_SCOPE, `Delayed navigation: ${JSON.stringify(delayedNavigation.value)}`)
        switch (delayedNavigation.value.type) {
          case 'exhibit': void navigateToExhibit(delayedNavigation.value.id); break
          case 'tour': void navigateToTour(delayedNavigation.value.id); break
        }
      }
    })

    // display navigation
    // async function navigateToDisplay (displayId: number): Promise<void> {
    //   if (!displayId) return
    //   if (routerStore.exhibit?.id === displayId) {
    //     return cancelChange('Route already active')
    //   }
    //   const potentialNextDisplay = exhibitStore.getExhibitById(displayId)
    //   if (!potentialNextDisplay) {
    //     console.log(`Could not find display ${displayId}`)
    //     return
    //   }
    //   await navigate({ name: routeNames.DISPLAY, params: { id: potentialNextDisplay.id } })
    // }

    // tour navigation
    async function navigateToTour (tourId: number): Promise<void> {
      if (!tourId) return
      if (experiencePlaying.value) {
        debugLog(DEBUG_SCOPE, 'Ignoring tour changes while experience is playing')
        delayedNavigation.value = { type: 'tour', id: tourId }
        return
      }
      delayedNavigation.value = null
      // DisplayManager adds an offset to zone ids to keep them unique, but not to the tour itself
      const offsetTourId = tourId - TOUR_ID_OFFSET
      if (routerStore.tour?.tourId === offsetTourId) {
        return cancelChange('Route already active')
      }
      const potentialNextTour = exhibitStore.getCardById(offsetTourId)
      if (!potentialNextTour) {
        console.log(`Could not find tour ${offsetTourId}`)
        return
      }
      await navigate({ name: routeNames.TOUR, params: { id: potentialNextTour.tourId } })
    }

    // exhibit navigation
    function cancelChange (message?: string): void {
      if (message) debugLog(DEBUG_SCOPE, `Cancelling location change: ${message}`)
    }

    const newSpaceSound = new Audio()
    newSpaceSound.src = new URL('../assets/sounds/new-space.mp3', import.meta.url).href
    newSpaceSound.load()
    async function playNavigationSound (): Promise<void> {
      if (displayDetails.connected) return
      if (localStorageStore.isSignLanguage && window.navigator.vibrate) {
        window.navigator.vibrate([200, 400])
      } else {
        newSpaceSound.currentTime = 0
        try {
          await newSpaceSound.play()
        } catch (err) {
          if (checkPlaybackError(err)) return
          console.error(err)
        }
      }
    }

    async function navigateToExhibit (exhibitId: number): Promise<void> {
      if (experiencePlaying.value) {
        debugLog(DEBUG_SCOPE, 'Ignoring exhibit changes while experience is playing')
        delayedNavigation.value = { type: 'exhibit', id: exhibitId }
        return
      }
      delayedNavigation.value = null
      if (route.name === routeNames.CALIBRATE) {
        debugLog(DEBUG_SCOPE, 'Ignoring router changes on current page')
        return
      }
      if (exhibitId <= 0) {
        debugLog(DEBUG_SCOPE, 'navigate to home')
        await navigate({ name: routeNames.HOME })
        return
      }
      if (!exhibitId) return
      if (routerStore.exhibit?.id === exhibitId) {
        return cancelChange('Route already active')
      }

      const potentialNextExhibit = exhibitStore.getExhibitById(exhibitId)
      if (!potentialNextExhibit) {
        console.log(`Could not find exhibit ${exhibitId}`)
        return
      }

      await navigate({ name: routeNames.EXHIBIT, params: { id: potentialNextExhibit.id } })
      await playNavigationSound()
    }

    // alert
    const alertDetails = reactive({
      show: false,
      title: 'No Alert',
      text: '',
    })
    function updateAlert (alertId: number): void {
      if (alertDetails.show) return
      const { lastShown } = localStorageStore.notificationHistory.getItem(alertId)
      const potentialNextAlert = exhibitStore.getExhibitById(alertId)
      if (!potentialNextAlert) {
        console.log(`Could not find alert ${alertId}`)
        return
      }
      // ignore same alert for `interval` mins
      const intervalSecs = (potentialNextAlert.interval || 10) * 60
      if (secondsBetween(lastShown, Date.now()) < intervalSecs) {
        return
      }
      alertDetails.title = potentialNextAlert.name ?? ''
      alertDetails.text = potentialNextAlert.alertMessage ?? ''
      alertDetails.show = true
      localStorageStore.notificationHistory.setItem(alertId, {
        lastShown: Date.now(),
      })
    }
    function dismissAlert (): void {
      alertDetails.show = false
    }

    // display
    const displayDetails = reactive({
      show: false,
      connected: false,
      inRangeExhibit: null as DeepReadonly<Exhibit> | null,
      connectedExhibit: null as DeepReadonly<Exhibit> | null,
    })
    function updateDisplay (exhibitId: number): void {
      debugLog(DEBUG_SCOPE, `updateDisplay: ${exhibitId}`)
      if (exhibitId === -1) {
        displayDetails.show = false
        displayDetails.inRangeExhibit = null
        return
      }

      const exhibit = exhibitStore.getExhibitById(exhibitId)
      if (!exhibit) {
        console.log(`Could not find display ${exhibitId}`)
        return
      }
      if (!exhibit.hasSyncedPlayback && !exhibit.souvenirId) {
        console.log(`Skipping display with no synced playback or souvenir ${exhibitId}`)
        return
      }
      if (displayDetails.connectedExhibit?.id === exhibitId) {
        return
      }
      displayDetails.inRangeExhibit = exhibit
      displayDetails.show = true
      // debugLog(DEBUG_SCOPE, `displayDetails: ${JSON.stringify(displayDetails)}`)
    }
    function openDisplay (): void {
      debugLog(DEBUG_SCOPE, 'openDisplay')
      displayDetails.connectedExhibit = displayDetails.inRangeExhibit
      displayDetails.show = false
      displayDetails.connected = true
      experienceStore.deactivate()
    }
    function dismissDisplay (): void {
      debugLog(DEBUG_SCOPE, 'dismissDisplay')
      displayDetails.connected = false
      displayDetails.connectedExhibit = null
      if (displayDetails.inRangeExhibit) {
        displayDetails.show = true
      }
    }

    // location subscription
    const exhibitStore = useExhibitStore()
    const locationSubscription = ref<(() => void) | null>(null)
    const { subscribeTopic } = useMqttSubscriber()
    localStorageStore.getUserData()

    const demoStore = useDemoStore()
    async function processLocationPayload (payload: LocationMessagePayload, source: string): Promise<void> {
      debugLog(DEBUG_SCOPE, `Process new location payload: ${JSON.stringify(payload)}`)
      // lastPayload = payload

      if (route.name === routeNames.NETWORK) {
        demoStore.payloads.push({ time: new Date(), source, payload })
        return
      }

      if (payload.tourId !== -1) {
        await navigateToTour(payload.tourId)
      } else if (payload.exhibitId > 0) {
        await navigateToExhibit(payload.exhibitId)
      }
      updateDisplay(payload.displayId)
      if (payload.alertId !== -1) {
        updateAlert(payload.alertId)
      }
      if (payload.tourId <= 0 && payload.exhibitId <= 0 && payload.displayId <= 0 && payload.alertId <= 0) {
        debugLog(DEBUG_SCOPE, 'No payload data')
        // delayGoHome()
        // await navigateToExhibit(0)
      } else {
        // cancelGoHome()
      }
    }

    const dataStore = useDataStore()
    if (dataStore.museumType === 'location') {
      watch(() => localStorageStore.tag, async (tag) => {
        locationSubscription.value?.()

        if (!tag) {
          await router.push({ name: routeNames.WELCOME, query: route.query })
          return
        }

        const topic = `location/${tag}`
        locationSubscription.value = subscribeTopic<LocationMessagePayload>(topic, async (payload) => {
          // Send confirmation that we received the message
          debugLog(DEBUG_SCOPE, `topic and payload: ${JSON.stringify({ topic, payload })}`)
          await processLocationPayload(payload, 'MQTT')
        })
      }, { immediate: true })
    }

    return {
      t,
      // alert
      cssVariables,
      alertDetails,
      dismissAlert,
      // display
      displayDetails,
      openDisplay,
      dismissDisplay,
    }
  },
})
</script>

<style lang="scss" scoped>
.alert-card {
  h1,
  p {
    text-align: center;
  }

  h1 {
    margin: 24px 0;
  }

  p {
    margin: 0 0 36px;
  }
  &.modal-card-visible {
    z-index: var(--z-modal-alert-card);
  }

  button {
    min-width: 100%;
  }
}

.display-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--nav-height);
  background-color: var(--primary);
  color: var(--white);
  z-index: var(--z-display-banner);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  text-align: center;
  padding: 0 16px;
}

.connect-link {
  display: flex;
  align-items: center;
  p {
    font-family: 'JW Knoll Bold';
    letter-spacing: 1.92px;
    text-transform: uppercase;
    margin-right: 8px;
  }
}

.banner-slide-enter-active,
.banner-slide-leave-active {
  transition: transform 0.5s ease;
}

.banner-slide-enter-from,
.banner-slide-leave-to {
  transform: translateY(-110%);
}

.connect-icon {
  animation: bump 1.2s infinite ease-in-out;
}

@keyframes bump {
  0% { transform: translateX(-3px); }
  50% { transform: translateX(3px); }
  100% { transform: translateX(-3px); }
}
</style>
