<template>
  <div v-if="details" class="inline-video-player">
    <Transition name="fade">
      <div v-show="showInlineVideoOverlay" class="inline-video-overlay flex-center" @click="onInlineInteraction">
        <button
          class="icon-btn icon-btn-transparent close-btn"
          aria-label="Close"
          @click="onClose"
        >
          <FontAwesomeIcon icon="fa-xmark" size="2x" />
        </button>
        <button
          class="icon-btn icon-btn-transparent volume-btn"
          aria-label="Volume"
          @click="onToggleVolume"
        >
          <FontAwesomeIcon :icon="volumeIcon" size="xl" />
        </button>
        <div v-if="!hideControls" class="flex-row flex-center actions">
          <button
            class="icon-btn icon-btn-transparent rewind-btn"
            aria-label="rewind"
            :disabled="!details.canRewind"
            @click="onRewind"
          >
            <FontAwesomeIcon icon="fa-rotate-left" size="3x" />
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <span>5</span>
          </button>
          <button
            class="icon-btn icon-btn-transparent play-pause-btn"
            :aria-label="details.ariaLabel"
            @click="onPlayPause"
          >
            <FontAwesomeIcon :icon="details.icon" size="3x" />
          </button>
          <button
            class="icon-btn icon-btn-transparent skip-btn"
            aria-label="skip"
            :disabled="!hasNext"
            @click="onSkip"
          >
            <FontAwesomeIcon icon="fa-forward-step" size="3x" />
          </button>
        </div>
        <div v-else class="flex-row flex-center actions hidden-controls">
          <button
            v-show="details.icon === 'fa-spinner'"
            class="icon-btn icon-btn-transparent play-pause-btn"
            :aria-label="details.ariaLabel"
          >
            <FontAwesomeIcon :icon="details.icon" size="3x" />
          </button>
        </div>
        <div class="track-bar">
          <div class="track-bar-progress" :style="{ width: details.percentComplete }" />
        </div>
        <div class="start-time" v-text="details.current" />
        <div class="end-time" v-text="details.length" />
      </div>
    </Transition>
    <video
      ref="inlineVideoRef"
      disablePictureInPicture
      playsinline
      :src="details.src"
      @click="onInlineInteraction"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'

import { useExperienceStore } from '@/stores/experience'

import { checkPlaybackError } from '@/utils/error'
import { secondsToMinutes } from '@/utils/time'

export default defineComponent({
  emits: {
    next: (): true => true,
    close: (): true => true,
  },

  props: {
    hasNext: { type: Boolean, required: true },
    hideControls: { type: Boolean, default: false },
  },

  setup (_props, { emit, expose }) {
    const experienceStore = useExperienceStore()
    const activeExperience = computed(() => experienceStore.activeExperience)
    const details = computed(() => {
      if (!activeExperience.value) return null
      const { playState, experience, type, src } = activeExperience.value
      const icon = playState.state === 'playing'
        ? playState.canplay ? 'fa-pause' : 'fa-spinner'
        : type === 'video' ? 'fa-headphones' : 'fa-play'
      const currentTime = Math.floor(playState.currentTime)
      const totalTime = Math.floor(playState.totalTime)
      const percentComplete = (currentTime / totalTime) * 100
      return {
        title: 'name' in experience ? experience.name : '',
        type,
        src,
        playState,
        current: secondsToMinutes(currentTime),
        currentTime,
        length: secondsToMinutes(totalTime),
        percentComplete: `${percentComplete}%`,
        ariaLabel: playState.state === 'playing' ? 'Pause' : 'Play',
        icon,
        canRewind: type !== 'video',
      }
    })

    const inlineVideoRef = ref<HTMLVideoElement | null>(null)
    watch(inlineVideoRef, (val) => {
      if (!val) return
      val.muted = false
      volumeIcon.value = 'fa-volume-high'
      onInlineInteraction()
      experienceStore.bindMediaEvents(val)
    })
    const inlineVideoPlayState = computed(() => {
      if (activeExperience.value?.type !== 'inlineVideo') return null
      return activeExperience.value.playState
    })
    watch([inlineVideoRef, inlineVideoPlayState], async ([vidRef, vidState]) => {
      if (!vidRef || !vidState) return
      if (vidState.state === 'playing') {
        if (!vidRef.paused) return
        try {
          await vidRef.play()
        } catch (err) {
          if (checkPlaybackError(err)) return
          console.error(err)
        }
      } else {
        if (vidRef.paused) return
        vidRef.pause()
      }
    }, { immediate: true })

    const inlineInteractionRecent = ref(true)
    const showInlineVideoOverlay = computed(() => {
      if (inlineVideoPlayState.value?.state !== 'playing') return true
      if (!inlineVideoPlayState.value?.canplay) return true
      if (inlineInteractionRecent.value) return true
      return false
    })
    let interactionTimeout = 0
    function onInlineInteraction (): void {
      inlineInteractionRecent.value = true
      window.clearTimeout(interactionTimeout)
      interactionTimeout = window.setTimeout(() => {
        inlineInteractionRecent.value = false
      }, 3000)
    }
    const volumeIcon = ref('fa-volume-high')
    function onToggleVolume (): void {
      if (!inlineVideoRef.value) return
      inlineVideoRef.value.muted = !inlineVideoRef.value.muted
      volumeIcon.value = inlineVideoRef.value?.muted ? 'fa-volume-xmark' : 'fa-volume-high'
    }

    function onPlayPause (): void {
      void experienceStore.playPause()
    }
    function onRewind (): void {
      if (!details.value?.canRewind) return
      const currentTime = Math.max(details.value.currentTime - 5, 0)
      experienceStore.updateCurrentTime(currentTime)
      if (inlineVideoRef.value) {
        inlineVideoRef.value.currentTime = currentTime
      }
    }
    function onClose (): void {
      emit('close')
    }
    function onSkip (): void {
      emit('next')
    }

    function resync (time: number): void {
      if (!inlineVideoRef.value) return
      inlineVideoRef.value.currentTime = time
    }
    expose({
      resync,
    })

    return {
      details,

      inlineVideoRef,
      showInlineVideoOverlay,
      volumeIcon,

      onToggleVolume,
      onInlineInteraction,

      onPlayPause,
      onRewind,
      onClose,
      onSkip,
    }
  },
})
</script>

<style lang="scss" scoped>
.inline-video-player {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: var(--black);
}

.inline-video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(var(--black), 0.2);
  z-index: 1;
}

.actions {
  justify-content: space-between;
  width: 300px;
  padding: 0;
  margin: 0 auto;
  .icon-btn {
    height: 50px;
    width: 50px;
    background: transparent;
    position: relative;
  }
  .rewind-btn > span {
    position: absolute;
    top: 18px;
    left: 22px;
  }
}

.hidden-controls {
  justify-content: center;
}

.track-bar,
.track-bar-progress {
  height: 4px;
  border-radius: 12px;
}

.track-bar {
  z-index: 1;
  position: absolute;
  background-color: var(--lightgray);
  top: unset;
  bottom: 48px;
  left: 36px;
  right: 36px;
}

.track-bar-progress {
  background-color: var(--primary);
  position: relative;
}

.start-time,
.end-time {
  font-family: 'JW Knoll';
  font-weight: bold;
  font-size: 12px;
  position: absolute;
  color: var(--lightgray);
  user-select: none;
  top: unset;
  bottom: 24px;
}

.start-time {
  left: 24px;
}

.end-time {
  right: 24px;
}

video {
  display: block;
  width: 100%;
  height: 100%;
}

.close-btn,
.volume-btn {
  position: fixed;
  top: 12px;
  height: 50px;
  width: 50px;
}

.close-btn {
  left: 12px;
}

.volume-btn {
  right: 12px;
}
</style>
