<template>
  <Teleport to="body">
    <InlineVideoPlayer
      v-if="details && isInlineVideo"
      :has-next="hasNext"
      @next="onSkip"
      @close="onClose"
    />
    <div
      class="modal-card modal-card-player"
      :class="{ 'modal-card-visible': detailsOpen }"
    >
      <div v-if="details" class="player flex-column flex-center">
        <div ref="trackBarRef" class="track-bar">
          <div class="track-bar-progress" :style="{ width: details.percentComplete }">
            <div
              class="track-bar-scrub"
              @mousedown="onThumbMouseDown"
              @touchstart="onThumbTouchStart"
            />
          </div>
        </div>
        <div class="start-time" v-text="details.current" />
        <div class="end-time" v-text="details.length" />
        <div class="flex-row flex-center actions">
          <button
            class="icon-btn rewind-btn"
            aria-label="rewind"
            :disabled="!details.canRewind"
            @click="onRewind"
          >
            <FontAwesomeIcon icon="fa-rotate-left" size="3x" />
            <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
            <span>5</span>
          </button>
          <button
            class="icon-btn play-pause-btn"
            :aria-label="details.ariaLabel"
            @click="onPlayPause"
          >
            <FontAwesomeIcon :icon="details.icon" size="3x" />
          </button>
          <button
            class="icon-btn skip-btn"
            aria-label="skip"
            :disabled="!hasNext"
            @click="onSkip"
          >
            <FontAwesomeIcon icon="fa-forward-step" size="3x" />
          </button>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts">
import { computed, defineComponent, onUnmounted, ref } from 'vue'

import InlineVideoPlayer from '@/components/InlineVideoPlayer.vue'
import { useExperienceStore } from '@/stores/experience'

import { getElementViewLeft } from '@/utils/layout'
import { secondsToMinutes } from '@/utils/time'

export default defineComponent({
  components: {
    InlineVideoPlayer,
  },

  emits: {
    next: (): true => true,
  },

  props: {
    open: { type: Boolean, required: true },
    hasNext: { type: Boolean, required: true },
  },

  setup (props, { emit }) {
    const experienceStore = useExperienceStore()
    const activeExperience = computed(() => experienceStore.activeExperience)
    const details = computed(() => {
      if (!activeExperience.value) return null
      const { playState, experience, type, src } = activeExperience.value
      const icon = playState.state === 'playing'
        ? playState.canplay ? 'fa-pause' : 'fa-spinner'
        : type === 'video' ? 'fa-headphones' : 'fa-play'
      const currentTime = Math.floor(playState.currentTime)
      const totalTime = Math.floor(playState.totalTime)
      const percentComplete = (currentTime / totalTime) * 100
      return {
        title: 'name' in experience ? experience.name : '',
        type,
        src,
        playState,
        current: secondsToMinutes(currentTime),
        currentTime,
        length: secondsToMinutes(totalTime),
        percentComplete: `${percentComplete}%`,
        ariaLabel: playState.state === 'playing' ? 'Pause' : 'Play',
        icon,
        canRewind: type !== 'video',
      }
    })
    function onPlayPause (): void {
      void experienceStore.playPause()
    }

    // inline video
    const isInlineVideo = computed(() => {
      return details.value?.type === 'inlineVideo'
    })

    // track bar
    const trackBarRef = ref<HTMLDivElement | null>(null)
    let wasPlaying = false
    function updateCurrentTime (e: MouseEvent | Touch, resume = false): void {
      if (!activeExperience.value || !trackBarRef.value) return
      if (activeExperience.value.playState.state === 'playing' && !resume) {
        wasPlaying = true
        void experienceStore.playPause()
      }
      const barWidth = trackBarRef.value.clientWidth
      let percentage = (e.clientX - getElementViewLeft(trackBarRef.value)) / barWidth
      percentage = percentage > 0 ? percentage : 0
      percentage = percentage < 1 ? percentage : 1
      const currentTime = activeExperience.value.playState.totalTime * percentage
      experienceStore.updatePlayState({ currentTime })
      if (resume) {
        experienceStore.updateCurrentTime(currentTime)
        if (wasPlaying) void experienceStore.playPause()
        wasPlaying = false
      }
    }

    function onDocumentMouseMove (e: MouseEvent): void {
      updateCurrentTime(e)
      e.stopPropagation()
    }
    function onDocumentMouseUp (e: MouseEvent): void {
      stopScrubbing()
      updateCurrentTime(e, true)
      e.stopPropagation()
      e.preventDefault()
    }
    function onThumbMouseDown (e: MouseEvent): void {
      updateCurrentTime(e)
      document.addEventListener('mousemove', onDocumentMouseMove)
      document.addEventListener('mouseup', onDocumentMouseUp)
      e.stopPropagation()
      e.preventDefault()
    }

    function onDocumentTouchMove (e: TouchEvent): void {
      const touch = e.changedTouches[0]
      updateCurrentTime(touch)
      e.stopPropagation()
    }
    function onDocumentTouchEnd (e: TouchEvent): void {
      stopScrubbing()
      const touch = e.changedTouches[0]
      updateCurrentTime(touch, true)
      e.stopPropagation()
      e.preventDefault()
    }
    function onThumbTouchStart (e: TouchEvent): void {
      const touch = e.changedTouches[0]
      updateCurrentTime(touch)
      document.addEventListener('touchmove', onDocumentTouchMove)
      document.addEventListener('touchend', onDocumentTouchEnd)
      e.stopPropagation()
      e.preventDefault()
    }
    function stopScrubbing (): void {
      document.removeEventListener('mouseup', onDocumentMouseUp)
      document.removeEventListener('mousemove', onDocumentMouseMove)
      document.removeEventListener('touchend', onDocumentTouchEnd)
      document.removeEventListener('touchmove', onDocumentTouchMove)
    }

    onUnmounted(() => {
      stopScrubbing()
    })

    function onRewind (): void {
      if (!details.value?.canRewind) return
      experienceStore.updateCurrentTime(Math.max(details.value.currentTime - 5, 0))
    }

    function onSkip (): void {
      emit('next')
    }
    function onClose (): void {
      experienceStore.deactivate()
    }

    const detailsOpen = computed(() => {
      if (!props.open) return false
      if (isInlineVideo.value) return false
      return true
    })

    return {
      details,
      onPlayPause,

      isInlineVideo,

      trackBarRef,
      onThumbMouseDown,
      onThumbTouchStart,

      onRewind,

      onSkip,
      onClose,

      detailsOpen,
    }
  },
})
</script>

<style lang="scss" scoped>
.modal-card-player {
  z-index: var(--z-modal-player-card);
  background: var(--modal-gradient);
  padding: 0;
  border: none;
  border-radius: 0;
  height: 160px;
  overflow: visible;
}

.player {
  padding: 0 20px 20px;
  position: relative;
}

.track-bar,
.track-bar-progress {
  height: 4px;
  border-radius: 12px;
}

.track-bar {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--lightgray);
}

.track-bar-progress {
  background-color: var(--primary);
  position: relative;
}

.track-bar-scrub {
  border-radius: 50%;
  position: absolute;
  top: -8px;
  bottom: -8px;
  right: 0;
  margin-right: -10px;
  background-color: var(--primary);
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.start-time,
.end-time {
  font-family: 'JW Knoll';
  font-weight: bold;
  font-size: 12px;
  position: absolute;
  top: 24px;
  color: var(--lightgray);
  user-select: none;
}

.start-time {
  left: 14px
}

.end-time {
  right: 14px;
}

.actions {
  justify-content: space-between;
  width: 100%;
  padding: 60px 36px 0;
  .icon-btn {
    height: 50px;
    width: 50px;
    background: transparent;
    position: relative;
  }
  .rewind-btn > span {
    position: absolute;
    top: 18px;
    left: 22px;
  }
}

@media (max-height: 500px) {
  .modal-card-player {
    height: 100px;
  }
  .player {
    .actions {
      padding: 30px 36px 0;
    }
  }
}
</style>
